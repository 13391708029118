import { useTranslate, useDelete, useNavigation, useModal } from "@refinedev/core";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Dropdown, Menu, Modal, Spin } from "antd";
import { IOrder } from "interfaces";
import { useState } from "react";

const { FormOutlined, FolderViewOutlined, DeleteOutlined } = Icons;

type OrderActionProps = {
  record: IOrder;
};

export const OrderActions: React.FC<OrderActionProps> = ({ record }) => {
  const { mutate } = useDelete();

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteOrder = (id: any) => {
    setIsDeleting(true);

    mutate({
      resource: "orders",
      id,
    }, {
      onSettled: () => setIsDeleting(false)
    });
  };

  const { visible: isDeleteModalVisible, show: showDeleteModal, close: closeDeleteModal } = useModal();

  const { edit, show } = useNavigation();
  const moreMenu = (record: IOrder) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        key="1"
        style={{
          fontWeight: 500,
        }}
        icon={
          <FolderViewOutlined
            style={{
              color: "green",
            }}
          />
        }
        onClick={() => show("orders", record.id)}
      >
        View Order
      </Menu.Item>
      <Menu.Item
        key="2"
        style={{
          fontWeight: 500,
        }}
        icon={
          <FormOutlined
            style={{
              color: "green",
            }}
          />
        }
        onClick={() => edit("orders", record.id)}
      >
        Edit Order
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{
          fontWeight: 500,
        }}
        icon={
          <DeleteOutlined
            style={{
              color: "red",
            }}
          />
        }
        onClick={showDeleteModal}
      >
        Delete Order
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Modal
        title="Delete Order"
        open={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        okButtonProps={{
          style: { backgroundColor: "red", width: 70 }
        }}
        okText={
          isDeleting
            ? <Spin indicator={<Icons.LoadingOutlined spin style={{ color: "#fff" }} />} />
            : "Delete"
        }
        onOk={() => {
          deleteOrder(record.id);
        }}
      >
        Are you sure you want to delete order <b>{record.orderNumber}</b>?
      </Modal>
      <Dropdown overlay={moreMenu(record)} trigger={["click"]}>
        <Icons.MoreOutlined
          onClick={(e) => e.stopPropagation()}
          style={{
            fontSize: 24,
          }}
        />
      </Dropdown>
    </>
  );
};
