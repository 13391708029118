import React, { useEffect, useRef, useState } from "react";
import { IResourceComponentsProps, useResourceParams } from "@refinedev/core";
import moment from "moment";
import { List, ExportButton } from "@refinedev/antd";
import { Space, notification, Timeline, Button, Descriptions } from "antd";
import { QRCodes } from "components";
import { OrderStatus, Loader, Invoice } from "components";
import common from "common";
import GeneratePdf from "./GeneratePdf";
import { getDateWithoutArray } from "./list";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import QRCode from "react-qr-code";

const getLabel = (label: string, idx: number) => {
  if (idx === 0) {
    return getDateWithoutArray(label);
  } else {
    return label;
  }
};

const getDateArray = (order: any) => {
  if (order && order.orderStatusArray) {
    return order.orderStatusArray.map((item: any, idx: number) => {
      const label = getLabel(item.label, idx);
      return {
        ...item,
        label: moment(label).format("MMMM Do YYYY, h:mm:ss a"),
      };
    });
  } else {
    return [];
  }
};

const getReceiptUrl = (receipt: string) => {
  // Convert the receipt string to the format required by Firebase.
  let receiptUrl = receipt;
  receiptUrl = receiptUrl.replaceAll("/", "%2F");
  receiptUrl = `https://firebasestorage.googleapis.com/v0/b/cbuserapp.appspot.com/o/${receiptUrl}?alt=media`;
  return receiptUrl;
};

const getReceiptUrlProduction = (receipt: string) => {
  // Convert the receipt string to the format required by Firebase.
  let receiptUrl = receipt;
  receiptUrl = receiptUrl.replaceAll("/", "%2F");
  receiptUrl = `https://firebasestorage.googleapis.com/v0/b/cbuserapp-production.appspot.com/o/${receiptUrl}?alt=media`;
  return receiptUrl;
};

const InvoiceImage = ({ receipt }: { receipt: string }) => {
  const [networkReceipt, setNetworkReceipt] = useState<string>(
    getReceiptUrlProduction(receipt)
  );

  return (
    <>
      <img
        src={networkReceipt}
        onError={() => setNetworkReceipt(getReceiptUrl(receipt))}
      />
    </>
  );
};

const Show: React.FC<any> = ({ order, customer, store }) => {
  const [openPdf, setOpenPdf] = useState(false);
  const componentRef = useRef<any>(null);
  const [openGenerate, setOpenGenerate] = useState(false);
  const dateArray = getDateArray(order);
  const [qrImage, setQrImage] = useState('');

  const exportData = () => {
    if (!order?.user) {
      notification.error({
        message: "Error",
        description: "User not assigned",
      });
    } else {
      setOpenPdf(true);
    }
  };

  let numBags = 0;
  if (order && order.bagCodes && order.bagCodes.length) {
    numBags = order.bagCodes.length;
  } else if (order && order.bags) {
    numBags = order.bags;
  }

  let storeName = "";
  if (store && store.title) {
    storeName = store.title;
  }

  const qrString = `{ "storeID": "${order.store}", "bagID": "${order.bagCodes[0]}" }`;

  const showDeliveryPdf = () => {
    // https://stackoverflow.com/questions/5433806/convert-embedded-svg-to-png-in-place

    const svg = document.getElementById("qr") as HTMLElement & SVGElement;
    
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    canvas.width = svg.clientWidth;
    canvas.height = svg.clientHeight;

    const svgAsXml = new XMLSerializer().serializeToString(svg);

    const loader = new Image();

    loader.onload = () => {
      ctx?.drawImage(loader, 0, 0);

      const pngUrl = canvas.toDataURL()
        .replace("image/png", "image/octet-stream");

      setQrImage(pngUrl);
      setOpenGenerate(true);
    };

    loader.src = `data:image/svg+xml,${encodeURIComponent(svgAsXml)}`;
  };

  return (
    <List
      title={"Order Details"}
      headerButtons={
        <Space>
          <Button onClick={() => history.back()}>Back</Button>
          <ExportButton onClick={() => exportData()} />
          <Button onClick={() => showDeliveryPdf()}>Delivery Label</Button>
        </Space>
      }
    >
      <GeneratePdf
        order={order}
        customer={customer}
        storeName={storeName}
        open={openGenerate}
        close={() => setOpenGenerate(false)}
        qr={qrImage}
      />
      {openPdf && <Invoice order={order} setOpenPdf={setOpenPdf} />}
      <div id="exportSpace" ref={componentRef}>
        <Descriptions title="Order Info" bordered column={2}>
          <Descriptions.Item label="Order Number">
            {order.orderNumber}
          </Descriptions.Item>
          {order && order.unipass && (
            <Descriptions.Item label="Unipass Number">
              {order.unipass}
            </Descriptions.Item>
          )}
          {order && order.deliveryCity && (
            <Descriptions.Item label="Delivery Address">
              {customer && customer.name}
              <br />
              {order.deliveryAddressType}
              <br />
              {order.deliveryLineAddress}
              <br />
              {order.deliveryCity}
              <br />
              {order.deliveryAddressPostalCode}
              <br />
              {order.deliveryCountry || ""}
            </Descriptions.Item>
          )}
          {order.notes && (
            <Descriptions.Item label="Delivery notes">
              {order.notes}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Service Type">
            {order.serviceType}
          </Descriptions.Item>
          <Descriptions.Item label="Bags">{numBags}</Descriptions.Item>
          <Descriptions.Item label="Paid">
            {order.isPaid ? "Done" : "Not Done"}
          </Descriptions.Item>
          <Descriptions.Item label="Logistic Company Provider">
            {order.logisticCompanyProvider}
          </Descriptions.Item>
          <Descriptions.Item label="Logistic Confirmation Number">
            {order.logisticConfirmationNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Confirmation">
            {order.paymentConfirmation}
          </Descriptions.Item>
          <Descriptions.Item label="Service Price">
            {order.currencySymbol || common.currency}
            {order.total || order.servicePrice}
          </Descriptions.Item>
          <Descriptions.Item label="QR Code">
            <QRCodes
              text={JSON.stringify({
                id: String(order.id),
                itemQuantity: order?.products?.length
                  ? Number(order?.products?.length)
                  : null,
                bagCount: order.bags ? Number(order.bags) : null,
                storeID: String(order.store),
                storeName: String(store?.title),
                price: order.servicePrice ? Number(order.servicePrice) : null,
                isPaid: order.isPaid ? order.isPaid : false, // default to false.
                paymentConfirmation: order.paymentConfirmation
                  ? String(order.paymentConfirmation)
                  : "", // can leave it empty
                customerId: "", // can leave it empty.
                deliveryStatus: order.orderStatus
                  ? String(order.orderStatus)
                  : "",
                logisticCompanyProvider: order.logisticCompanyProvider
                  ? String(order.logisticCompanyProvider)
                  : "",
                logisticCompanyNumber: order.logisticConfirmationNumber
                  ? String(order.logisticConfirmationNumber)
                  : "",
                orderType: order.serviceType ? String(order.serviceType) : "",
                invoice: "",
              })}
              size={150}
            />

            <div style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}>
              <QRCode
                id="qr"
                level="M"
                size={150}
                value={qrString}
              />
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Order Status">
            <OrderStatus status={order.orderStatus} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Order Status Info" bordered>
          <Descriptions.Item>
            <Timeline mode={"left"} items={dateArray} />
          </Descriptions.Item>
        </Descriptions>
        {order.receipt && (
          <Descriptions title="Receipt" bordered>
            <Descriptions.Item>
              <InvoiceImage receipt={order.receipt} />
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
    </List>
  );
};

const LoadStore: React.FC<any> = ({ order, customer }) => {
  const [store, setStore] = useState<any>(null);

  useEffect(() => {
    const loadStore = async () => {
      if (store === null) {
        const storeID = order.store;
        if (storeID) {
          const docRef = doc(db, "stores", storeID);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const store = docSnap.data();
            setStore(store);
            return;
          } else {
            const q = query(
              collection(db, "stores"),
              where("uid", "==", storeID)
            );
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs.length > 0) {
              const data = querySnapshot.docs[0].data();
              setStore(data);
            }
          }
        }
      }
    };

    loadStore();
  }, []);

  if (store === null) {
    return <Loader />;
  } else {
    return <Show order={order} customer={customer} store={store} />;
  }
};

const LoadCustomer: React.FC<any> = ({ order }) => {
  const [customer, setCustomer] = useState<any>(null);

  useEffect(() => {
    const loadCustomer = async () => {
      if (customer === null) {
        const customerID = order.user;
        if (customerID) {
          const docRef = doc(db, "users", customerID);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const customer = docSnap.data();
            setCustomer(customer);
          }
        }
      }
    };

    loadCustomer();
  }, []);

  if (customer === null) {
    return <Loader />;
  } else {
    return <LoadStore order={order} customer={customer} />;
  }
};

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const [order, setOrder] = useState<any>(null);
  const { id } = useResourceParams();

  useEffect(() => {
    const loadOrder = async () => {
      if (order === null) {
        const orderID = id?.toString();
        if (orderID) {
          const docRef = doc(db, "orders", orderID);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const order = docSnap.data();
            setOrder(order);
          }
        }
      }
    };

    loadOrder();
  }, []);

  if (order === null) {
    return <Loader />;
  } else {
    return <LoadCustomer order={order} />;
  }
};
